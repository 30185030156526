/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  .neighbor-testimonial-dialog-content-cont {
    padding: 16px 16px 0;
    white-space: pre-wrap;
    .neighbor-testimonial-dialog-header-cont {
      display: grid;
      grid-template-columns: 64px 1fr;
      gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid ${COLORS.black200};
      .neighbor-testimonial-dialog-header-wrap {
        text-align: left;
      }
    }

    .neighbor-testimonial-dialog-use-case-cont {
      margin-top: 32px;
      text-align: left;
      .neighbor-testimonial-dialog-use-case-list {
        display: grid;
        gap: 24px;
        margin-top: 28px;
      }
    }

    .dialog-call-to-action-cont {
      display: grid;
      gap: 8px;
      margin-top: 24px;
      padding: 16px;
      border: 1px solid ${COLORS.black300};
      border-radius: 8px;
      .call-to-action-text {
        :global(.typo-xs) {
          color: ${COLORS.black700};
        }
      }
    }
  }
`;

export default styles;
