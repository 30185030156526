/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const { desktop } = BREAKPOINT;

const styles = css`
  .neighbor-plans-container {
    .title {
      text-align: center;
      margin-bottom: 48px;
    }
    .jal-link {
      text-align: center;
      text-decoration: underline;
      margin-top: -30px;
      margin-bottom: 48px;
    }
    .neighbor-plans {
      @media screen and (min-width: ${desktop}px) {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap;
        max-width: 1024px;
        gap: 12px;
      }
      :global(.neighbor-plan) {
        flex-shrink: 0;
        margin-bottom: 24px;
        @media screen and (min-width: ${desktop}px) {
          width: 300px;
          margin-bottom: 0;
          &:nth-child(2n + 1) {
            margin-left: 0;
          }
          &:nth-child(n + 3) {
            margin-top: 32px;
          }
        }
        @media screen and (min-width: 1025px) {
          width: 304px;
          &:nth-child(2n + 3) {
            margin-left: 32px;
          }
          &:nth-child(3) {
            margin-top: 0;
          }
        }
      }
      :global(.swiper) {
        :global(.swiper-wrapper) {
          :global(.swiper-slide) {
            width: 311px;
            height: auto;
          }
        }
        :global(.swiper-pagination-bullets) {
          line-height: 30px;
        }
        :global(.swiper-pagination-bullet) {
          margin: 0 2px;
          width: 20px;
          height: 6px;
          background: ${COLORS.black200};
          border-radius: 3px;
          opacity: 1;
        }
        &:global(.swiper-pagination-bullet-active) {
          width: 40px;
          background: ${COLORS.black900};
        }
        :global(.swiper-pagination) {
          position: static;
        }
      }
    }
    .button-container {
      margin-top: 24px;
    }
    :global(.coin-dialog-wrapper) {
      text-align: center;
      margin: 56px 0;
    }
    .plan-notice {
      max-width: 600px;
      margin: 0 auto;
      .notice-heading {
        padding-bottom: 24px;
        text-align: center;
      }
      .notice-description {
        padding: 8px 0 16px;
        white-space: pre-wrap;
        :global(.typo-xs) {
          color: ${COLORS.black700};
        }
      }
    }
  }
`;

export default styles;
