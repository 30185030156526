/* eslint-disable */
import Image from 'next/image';
import React from 'react';

import { TypoXXS, TypoSBold } from '@atoms/Typos';

import styles from './css';

type Props = {
  propertyImgKey: string;
  dayTitle: string;
  propertyName: string;
  description: string;
};

const UseCaseCard = ({
  propertyImgKey,
  dayTitle,
  propertyName,
  description,
}: Props): JSX.Element => (
  <div className="use-case-card-cont">
    <div className="use-case-card-image">
      <Image
        src={`${process.env.NEXT_PUBLIC_GCS_URL}/common/testimonials/${propertyImgKey}.jpg`}
        alt=""
        fill={true}
        style={{
          objectFit: 'cover',
        }}
      />
    </div>
    <div>
      <p className="use-case-card-heading">{dayTitle}</p>
      <h3>
        <TypoSBold text={propertyName} />
      </h3>
      <p>
        <TypoXXS text={description} />
      </p>
    </div>

    <style jsx>{styles}</style>
  </div>
);

export default UseCaseCard;
