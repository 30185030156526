/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  .color-gray {
    color: #222222;
  }
  .color-blue {
    color: ${COLORS.primary};
  }
`;

export default styles;
